import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";  // Import it

import './css/generative.css';
import Home from './components/Home';
import Glass from './components/Glass';
import Nfts from './components/Nfts';
import Webdev from './components/Webdev';
import Marketing from './components/Marketing';
import Addlexp from './components/Addlexp';
import Books from './components/Books';
import BookFrazyCreek from './components/BookFrazyCreek'; 
import BookChroo from './components/BookChroo'; 
import BookPirates from './components/BookPirates'; 
import BookWoods from './components/BookWoods'; 
import BookTales from './components/BookTales'; 
import Skills from './components/Skills';
import Carpentry from './components/Carpentry';
import Education from './components/Education';
import Music from './components/Music';
import MusicJoplin from './components/MusicJoplin';
import MusicScott from './components/MusicScott';
import MusicLamb from './components/MusicLamb';
import MusicJim from './components/MusicJim';
import Art from './components/Art';
import Other from './components/Other';
import Socials from './components/Socials';
import Contact from './components/Contact';

import Puzzles from './components/Puzzles';
import PuzzleDetail from './components/PuzzleDetail';

import Resume from './components/Resume';
import ResumeEditorial from './components/ResumeEditorial';
import ResumeTech from './components/ResumeTech';
import ResumeGeneral from './components/ResumeGeneral';
import ResumeSocialMedia from './components/ResumeSocialMedia';
import ResumeDevAdvocate from './components/ResumeDevAdvocate';
import ResumeProductManager from './components/ResumeProductManager';
import ResumeTechWriter from './components/ResumeTechWriter';
import ResumeWeb3 from './components/ResumeWeb3';

import 'bootstrap/dist/css/bootstrap.min.css';

// NOTE: IF ESLINT ERRORS, TRY THIS: Change build command to:  
// CI= npm run build
// This allows Netlify to NOT treat ESlint warnings as errors

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/glass" element={<Glass />} />
        <Route path="/nfts" element={<Nfts />} />
        <Route path="/webdev" element={<Webdev />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/addlexp" element={<Addlexp />} />
        <Route path="/books/*" element={<BooksRoutes />} /> 
        <Route path="/skills" element={<Skills />} />
        <Route path="/carpentry" element={<Carpentry />} />
        <Route path="/education" element={<Education />} />
        <Route path="/music/*" element={<MusicRoutes />} />
        <Route path="/resume/*" element={<ResumeRoutes />} />
        <Route path="/art" element={<Art />} />
        <Route path="/other" element={<Other />} />
        <Route path="/socials" element={<Socials />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/puzzles/*" element={<PuzzleRoutes />} />
      </Routes>
    </Router>
  );
}

function BooksRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Books />} /> {/* Default /books route */}
      <Route path="frazy-creek" element={<BookFrazyCreek />} /> 
      <Route path="chroo" element={<BookChroo />} /> 
      <Route path="pirates-of-pamlico-sound" element={<BookPirates />} /> 
      <Route path="woods-confection" element={<BookWoods/>} /> 
      <Route path="tales-of-the-midwest" element={<BookTales />} /> 
    </Routes>
  );
}

function MusicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Music />} /> {/* Default /music route */}
      <Route path="joplin" element={<MusicJoplin />} /> 
      <Route path="scott" element={<MusicScott />} /> 
      <Route path="lamb" element={<MusicLamb />} /> 
      <Route path="jim" element={<MusicJim />} /> 
    </Routes>
  );
}

function ResumeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Resume />} /> {/* Default /resume route */}
      <Route path="editorial" element={<ResumeEditorial />} /> 
      <Route path="tech" element={<ResumeTech />} /> 
      <Route path="general" element={<ResumeGeneral />} /> 
      <Route path="social-media" element={<ResumeSocialMedia />} /> 
      <Route path="developer-advocate" element={<ResumeDevAdvocate />} /> 
      <Route path="technical-writer" element={<ResumeTechWriter />} /> 
      <Route path="product-manager" element={<ResumeProductManager />} /> 
      <Route path="web3" element={<ResumeWeb3 />} /> 
    </Routes>
  );
}

function PuzzleRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Puzzles />} /> 
      <Route path="puzzle-detail" element={<PuzzleDetail />} /> 
    </Routes>
  );
}

export default App;
