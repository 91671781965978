import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Resume = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">

          <p className="breadcrumbs"><a href="/skills">← back to skills page</a></p>

            <h1>RESUMES</h1>
            
            <p>Here are a few resumes tailored for specific roles that I would enjoy:</p>

            <ul>
                <li>⛓️ Resume focused on <a href="/resume/web3">web3 / blockchain</a>.</li>
                <li>💻 Resume focused on <a href="/resume/tech">tech qualifications</a>.</li>
                <li>📊 Resume focused on <a href="/resume/product-manager">tech-product management</a>.</li>
                <li>🤝 Resume focused on <a href="/resume/developer-advocate">developer advocacy / developer relations</a>.</li>       
                <li>📝 Resume focused on <a href="/resume/technical-writer">tech writing / documentation</a>.</li>       
                <li>✍️ Resume focused on <a href="/resume/editorial">editorial qualifications</a>.</li>
                <li>📈 Resume focused on <a href="/resume/social-media">social media, SEO, marketing qualifications</a>.</li>
                <li>🏢 Resume focused on <a href="/resume/general">general business management</a>.</li>
            </ul>

        </div>

        <MainFooter />

    </div>
  );
}

export default Resume;
