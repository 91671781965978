import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Home = () => {

  return (
    <div>
         
         <MainHeader />

         <div className="mainpage">

            <h1>GREETINGS, FRIEND!</h1>
            <p>This is a resume-type web site for yours truly, Jim Dee. Thanks for visiting my little corner of the internet, JPD3.com. (My initials are "JPD"; the "3" part means nothing. It was simply the shortest available domain name.) This web site organizes my numerous professional qualifications as well as personal interests / pursuits. The images below, btw, were made using Midourney AI!</p>

            <div className="allCards">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-glass.webp" />
                    <Card.Body>
                      <Card.Title>Stained Glass</Card.Title>
                      <Card.Text>
                        View some of my stained glass projects. Reach out for commissions!
                      </Card.Text>
                      <Link to="/glass">
                        <div className="d-grid"><Button variant="dark">Cool, let's go!</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-nfts.webp" />
                    <Card.Body>
                      <Card.Title>NFTs</Card.Title>
                      <Card.Text>
                        I've made 350k NFTs for clients who've sold $40+ million in images from my generative system.
                      </Card.Text>
                      <Link to="/nfts">
                        <div className="d-grid"><Button variant="dark">Crazy, but ok.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-webdev.webp" />
                    <Card.Body>
                      <Card.Title>Web Development</Card.Title>
                      <Card.Text>
                        I spent a decade doing web sites and database applications profesisonally. I guess I still do them.
                      </Card.Text>
                      <Link to="/webdev">
                        <div className="d-grid"><Button variant="dark">Geek out.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-marketing.webp" />
                    <Card.Body>
                      <Card.Title>Marketing Career</Card.Title>
                      <Card.Text>
                        I spent another decade as a marketing & business development director at a CPA firm.
                      </Card.Text>
                      <Link to="/marketing">
                        <div className="d-grid"><Button variant="dark">Gonna click?</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-addl.webp" />
                    <Card.Body>
                      <Card.Title>Additional Experience</Card.Title>
                      <Card.Text>
                        And I did another decade of other work -- mostly in publishing, writing, editing, corporate communications.
                      </Card.Text>
                      <Link to="/addlexp">
                       <div className="d-grid"><Button variant="dark">Go to press.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-books.webp" />
                    <Card.Body>
                      <Card.Title>My Books</Card.Title>
                      <Card.Text>
                        I've written five books so far -- two novels, a novella, a memoir, and a feature screenplay.
                      </Card.Text>
                      <Link to="/books">
                       <div className="d-grid"><Button variant="dark">Check 'em out!</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-tech.webp" />
                    <Card.Body>
                      <Card.Title>Skills & Resumes</Card.Title>
                      <Card.Text>
                        List of tech and professional skills I have, as well as various resumes by functional area.
                      </Card.Text>
                      <Link to="/skills">
                        <div className="d-grid"><Button variant="dark">My keywords.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-carpentry.webp" />
                    <Card.Body>
                      <Card.Title>Carpentry</Card.Title>
                      <Card.Text>
                        Been building since the 90s -- have rehabbed two homes, and have picked up most trades.
                      </Card.Text>
                      <Link to="/carpentry">
                       <div className="d-grid"><Button variant="dark">Measure twice...</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-puzzles.webp" />
                    <Card.Body>
                      <Card.Title>Jester Puzzles</Card.Title>
                      <Card.Text>
                        A little 2025 project involving art, coding, print-on-demand integrations, and more.
                      </Card.Text>
                      <Link to="/puzzles">
                       <div className="d-grid"><Button variant="dark">Piece it together.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-education.webp" />
                    <Card.Body>
                      <Card.Title>Education</Card.Title>
                      <Card.Text>
                        School / education / etc. -- usual resume stuff.
                      </Card.Text>
                      <Link to="/education">
                       <div className="d-grid"><Button variant="dark">English major.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-music.webp" />
                    <Card.Body>
                      <Card.Title>Music</Card.Title>
                      <Card.Text>
                        Lifelong pianist, guitar player, composer. Here are many pieces, many with sheet music.
                      </Card.Text>
                     <Link to="/music">
                        <div className="d-grid"><Button variant="dark">I hear ya...</Button></div>
                     </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-art.webp" />
                    <Card.Body>
                      <Card.Title>Digital Art</Card.Title>
                      <Card.Text>
                        Various digital artistic pursuits here, some crossover with the NFT item above.
                      </Card.Text>
                      <Link to="/art">
                       <div className="d-grid"><Button variant="dark">See for yourself.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-camera.webp" />
                    <Card.Body>
                      <Card.Title>Other Interests</Card.Title>
                      <Card.Text>
                        Bunch of other random stuff I find myself doing -- photography, raw foods, dog stuff, who knows.
                      </Card.Text>
                      <Link to="/other">
                       <div className="d-grid"><Button variant="dark">Clicker thing.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-social.webp" />
                    <Card.Body>
                      <Card.Title>Social Media</Card.Title>
                      <Card.Text>
                        Links to various online homes, etc.
                      </Card.Text>
                      <Link to="/socials">
                       <div className="d-grid"><Button variant="dark">Find links.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="card-contact.webp" />
                    <Card.Body>
                      <Card.Title>Contact Me</Card.Title>
                      <Card.Text>
                        See something here you want to reach out about?
                      </Card.Text>
                      <Link to="/contact">
                        <div className="d-grid"><Button variant="dark">Contact me.</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

            </div>

            <div classNam="clear"></div>
            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Home;
