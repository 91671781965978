import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { puzzleData, aboutPuzzleData } from './puzzleData';

// Convert puzzleData object to an array, shuffle, then map it in JSX
const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
};

const Puzzles = () => {

  const shuffledPuzzles = shuffleArray(Object.values(puzzleData));

  return (
    <div>
         
         <MainHeader />

         <div className="mainpage">

            <h1>🧩 JESTER PUZZLES</h1>

            <p><b>Jester Puzzles</b> invites you into the <i>Neon Noir Carnival,</i> a surreal dreamscape where reality bends, time twists, and shadows dance in electric hues. This collection of high-quality jigsaw puzzles brings to life a world of enigmatic jesters, masked tricksters, celestial fortune tellers, and eerie carnival spirits—each scene infused with luminous colors, intricate details, and a sense of both wonder and mystery. Designed for those who crave immersive, artistic challenges, these puzzles are more than just pieces to assemble; they are gateways to a place where the fantastical meets the unknown, where the lights never dim, and where every completed image reveals a story waiting to be unraveled.</p>

            <div className="allCards">

                {shuffledPuzzles.map((puzzle, index) => (
                    <div className="singleCard" key={index}>
                    <Card>
                        <Card.Img variant="top" src={`puzzles_500px/${puzzle.image}`} />
                        <Card.Body>
                        <Card.Title>{puzzle.title}</Card.Title>
                        <Card.Text>{puzzle.description}</Card.Text>
                        <Link to={`/puzzles/puzzle-detail?design=${puzzle.linkAlias}`}>
                            <div className="d-grid">
                            <Button variant="dark">More information & Amazon link</Button>
                            </div>
                        </Link>
                        </Card.Body>
                    </Card>
                    </div>
                ))}

            </div>

            <div className="clear"></div>

            <h2>🧩 About Jester Puzzles</h2>
            <p>{aboutPuzzleData}</p>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Puzzles;
