import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { puzzleData, aboutPuzzleData } from './puzzleData';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PuzzleDetail = () => {
  // Get query parameter from URL
  const query = new URLSearchParams(useLocation().search);
  const designParam = query.get("design"); // Get "design" param from URL

  // Find the puzzle that matches the linkAlias
  const puzzle = Object.values(puzzleData).find(
    (p) => p.linkAlias === designParam
  );

// Function to generate a random color
const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  
// Component to display text with cycling colors
const ColorfulText = ({ text }) => {
    const [colors, setColors] = useState(
      text.split("").map(() => getRandomColor())
    );
  
    useEffect(() => {
        const interval = setInterval(() => {
            setColors(text.split("").map(() => getRandomColor()));
        }, 200); // Change colors every second
    
        return () => clearInterval(interval); // Cleanup on unmount
        }, [text]);
    
        return (
        <span className="jester">
            {text.split("").map((char, index) => (
            <span
                key={index}
                style={{
                color: colors[index],
                transition: "color 0.5s ease-in-out",
                }}
            >
                {char}
            </span>
            ))}
        </span>
        );
    };
  
    

  return (
    <div>
      <MainHeader />

      <div className="mainpage">

        <p className="breadcrumbs"><a href="/puzzles">back to all 🃏 Jester Puzzles</a></p>

        {puzzle ? (
          <>
            <h1>🧩 "<span className="jester"><ColorfulText text={puzzle.title} /></span>" -- A Jigsaw Puzzle by <span className="jester"><ColorfulText text="🃏Jester Puzzles!" /></span></h1>

            

            <img className="bigPuzzle" src={`/puzzles_500px/${puzzle.image}`} alt={puzzle.title} style={{ width: "100%", maxWidth: "500px" }} />
            {/* Render longDescription properly with paragraphs */}
            <div className="puzzleLongDescription">
            {puzzle.longDescription.split("\n").map((para, index) =>
                para.trim() ? <p key={index}>{para}</p> : <br key={index} />
            )}
            </div>

            <div className="puzzleLinkBox">
              <h2>🧩 Puzzle and <span className="jester"><ColorfulText text={puzzle.title} /></span> Merch Links!</h2>
              <p><b>These links are placeholders at the moment</b>. As of Feb. 13, 2025, I'm working on approval from Etsy. After that, I'll integrate with Amazon.</p>

              <div className="puzzleLinksCol">
                <h3><span className="jester"><ColorfulText text="@" /></span>Etsy:</h3>
                <p><a className="puzzleButton" href={puzzle.amazonLink} target="_blank" rel="noopener noreferrer">
                🧩 Buy the {puzzle.title} jigsaw puzzle!
                </a></p>

                <p><a className="puzzleButton" href={puzzle.merch3Link} target="_blank" rel="noopener noreferrer">
                🖼️ Get a {puzzle.title} poster!
                </a></p>
                
                <p><a className="puzzleButton" href={puzzle.merch2Link} target="_blank" rel="noopener noreferrer">
                🃏 Get {puzzle.title} playing cards!
                </a></p>


              </div>

              <div className="puzzleLinksCol">
                <h3><span className="jester"><ColorfulText text="@" /></span>Amazon:</h3>
                <p><a className="puzzleButton" href={puzzle.amazonLink} target="_blank" rel="noopener noreferrer">
                🧩 Buy the {puzzle.title} jigsaw puzzle!
                </a></p>

                <p><a className="puzzleButton" href={puzzle.merch3Link} target="_blank" rel="noopener noreferrer">
                🖼️ Get a {puzzle.title} poster!
                </a></p>
                
                <p><a className="puzzleButton" href={puzzle.merch2Link} target="_blank" rel="noopener noreferrer">
                🃏 Get {puzzle.title} playing cards!
                </a></p>
              </div>

              <div className="clear"></div>

            </div>
          </>
        ) : (
          <div>
            <h1>😈 Invalid Puzzle Request</h1>
            <p>You messed around with the URL, didn't you? Well, there's nothing to see without a valid "design" parameter. You should <a href="/puzzles">head back to the main puzzles page</a>.</p>
          </div>
        )}

        <div className="clear"></div>

        <hr></hr>
        <h2>🧩 About <span className="jester"><ColorfulText text="Jester Puzzles!" /></span></h2>
        <p>{aboutPuzzleData}</p>

        <Link to="/puzzles">
              <Button variant="primary">🧩 Back to All Puzzles</Button>
            </Link>

        
        <p className="topButton"><a href="#top">/top</a></p>
      </div>

      <MainFooter />
    </div>
  );
};

export default PuzzleDetail;
