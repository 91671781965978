import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Nfts = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>GENERATIVENFTS.io</h1>
            <p className="resyears">August 2021 to present:</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="gennfts-logo.jpg" />

              <i className="fa fa-info-circle" aria-hidden="true"></i> Want to know more? Read my writings on <a href="https://medium.com/web-design-web-developer-magazine" target="_blank">generative art programming for NFTs</a> on Medium.com.


              <img className="businessLogos logospacer" src="contact.jpg" />
              <a href="contact">Contact me now about spearheading an NFT project for you.</a>
            </div>

            <p className="resLinks">📝 <b>WEB3 RESUMES:</b>&nbsp;&nbsp;
              <a className="singleResume" href="/resume/web3">Web3 / Blockchain</a>
              <a className="singleResume" href="/resume/developer-advocate">Developer Relations</a>
              <a className="singleResume" href="/resume/technical-writer">Tech Writing</a>
              </p>

            <p className="restitle"><strong><span className="highlighter">Co-Founder and Chief Generative Art Programmer,</span></strong> <a href="https://generativenfts.io/" target="_blank" rel="nofollow">GenerativeNFTs.io</a>. We are a web3 consulting company specializing in large-scale NFT drop projects.</p>

            <ul>
                <li>Co-founded <a href="https://generativenfts.io/" target="_blank" rel="nofollow">GenerativeNFTs.io</a> in early 2021 after going viral for my generative art coding writings (considered perhaps the world's leading authority on <a href="https://medium.com/web-design-web-developer-magazine/how-to-prepare-a-rarity-table-for-a-generative-nft-art-programmer-1c081db52f29" target="_blank">rarity tables</a> and other aspects of generative NFT coding).</li>
                <li>Have served on ~30 worldwide generative NFT drop teams since early 2021 with <b> $40+ million USD</b> in total sales.</li>
                <li>Particularly experienced in complex, international, large-scale web3 project management including art pipeline management, art/metadata coding, NFT team strategy advisory services, and web3 dev coding.</li>
                <li>Creator / writer of a popular <a target="_blank" href="https://medium.com/web-design-web-developer-magazine">web3/NFT blog</a>.</li>
                <li>Organized archive of ~200 original NFT articles since early 2021 -- see <a target="_blank" href="https://generativenfts.io/blog"> https://generativenfts.io/blog</a> -- covers generative art, NFT team building, NFT team management, NFT strategy, branding, and much more in the web3 space.</li>
                <li>Especially sought out for generative NFT coding, for which I've produced 250k+ generative NFTs to date (with 50k more on deck).</li>
                <li>Have spearheaded many sellout sets (all on the ETH chain except for one on the Theta chain -- the “Napoleon Dynamite” drop by actor Jon Heder).</li>
                <li>Have personally deployed ~30 smart contracts onto the Ethereum mainnet.</li>
                <li>Comfortable working with executives and team-leads worldwide.</li>
                <li>Excellent contacts worldwide in the NFT / crypto space, as well as metaverse builders and entrepreneurs.</li>
                <li><b>Tech stack:</b> Solidity / Hardhat, React/JSX, JavaScript, PHP, MySQL, HTML/CSS.</li>
                <li>For permanent storage, I'm a huge proponent of Arweave. For anyone interested, I was <a href="https://www.youtube.com/watch?v=zvKRk52M4pI" target="_blank">featured on Arweave's ArCast</a>, in which I explained how Arweave works and why it's better than IPFS.</li>
                
            </ul>
            <h4>Selected Project Highlights</h4>
            <ul>

              <li><img className="nfticons" src="02.jpg" /><a href="https://opensea.io/collection/superfuzz-the-bad-batch" target="_blank">SuperFuzz Bad Guys</a> (sold out -- we did the generative art coding and participated in team strategy.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="03.jpg" /><a href="https://opensea.io/collection/thepppandas" target="_blank">PPPandas</a> (sold out -- we did the generative art coding.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="01.jpg" /><a href="https://opensea.io/collection/superfuzz-the-good-guys" target="_blank">SuperFuzz Good Guys</a> (sold out -- we did the generative art coding and participated on the team.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="07.jpg" /><a href="https://opensea.io/collection/chefboirdogemutts" target="_blank">Chef Dogs Gen1</a> (now minting -- we did the gen art, smart contract, minting app.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="10.jpg" /><a href="https://opensea.io/collection/sandhelm" target="_blank">Sandhelm</a> (now minting -- we did the gen art, smart contract, minting app, and more.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="12.jpg" /><a href="https://opensea.io/collection/prayinghandsclub" target="_blank">Praying Hands Club</a> (sold out -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="38.jpg" /><a href="https://opensea.io/collection/the-crypto-mos" target="_blank">The Crypto Mos</a> (gen art, smart contract, minting app.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="13.jpg" /><a href="https://opensea.io/collection/imakittycat" target="_blank">I'm a Kitty Cat</a> (now minting -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="18.jpg" /><a href="https://thetadrop.com/marketplace/edition/type_tpqv6d16v5fp0gudtt0vyf6qsqm" target="_blank">Order of the Tigons</a> (sold out -- the Napoleon Dynamite drop on Theta chain -- we did the art coding and participated on the team.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="20.jpg" /><a href="https://opensea.io/collection/yearofthewoman" target="_blank">Year of the Woman</a> (sold out -- gen art, smart contract, minting app, marketing/strategy team members.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="24.jpg" /><a href="https://opensea.io/collection/loopieloo" target="_blank">Loopies</a> (minted out -- my own free mint project -- did everything.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="26.jpg" /><a href="https://opensea.io/collection/astralpioneers" target="_blank">Astral Pioneers</a> (now minting -- did everything, plus an amazing guy on our team did the art.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="29.jpg" /><a href="https://opensea.io/collection/cbrdmixedbreeds" target="_blank">Chef Dogs Gen2</a> (now minting -- gen art, smart contract, minting app.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="30.gif" /><a href="https://nftgreetings.xyz" target="_blank">NFTGreetings.xyz</a> (personal project with 11 mainnet contracts/minting apps launched so far; with collabs slated.)</li>
              <li className="clearing"></li>
              
              <li><img className="nfticons" src="31.jpg" /><a href="https://frowny.town" target="_blank">FrownyTown</a> (project pending -- gen art, smart contract, minting app, advisory services.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="32.jpg" /><a href="https://lapinc.io" target="_blank">LAPiNC</a> (project pending -- gen art, smart contract, minting app, advisory services.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="33.jpg" /><a href="https://spiritualbeings.io" target="_blank">Spiritual Beings</a>: (project pending -- gen art, smart contract, minting app, team participation. Top quality team lead by former chief of staff of Boss Beauties.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="34.jpg" /><a href="https://mightymetas.com" target="_blank">Mighty Metas</a>  (project pending -- gen art, smart contract, minting app, advisory services.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="37.jpg" /><a href="https://opensea.io/collection/innerkiddosclub" target="_blank">Inner Kiddos Club</a> (just the smart contract & minting app.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="28.jpg" /><a href="https://opensea.io/collection/cheekyrangas" target="_blank">Cheeky Rangas</a> (gen art, smart contract, minting app, <a href="https://mint.cheekyrangas.com/" target="_blank">free to mint</a>.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="35.jpg" /><a href="https://firstapewivesclub.io" target="_blank">First Ape Wives Club</a> (project pending -- gen art, smart contract, minting app, team members.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="https://glitchdeck.xyz/79.gif" /><a href="https://glitchdeck.xyz/">GlitchDeck</a> (personal project consisting of 8,100 unique, generated, animated playing cards.)</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="36.jpg" /><a href="https://divasbyblaq.art/" target="_blank">Divas by BLAQ</a> (project pending -- gen art, smart contract, minting app, etc.)</li>
              <li className="clearing"></li>

            </ul>
            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Nfts;
