const aboutPuzzleData =
  "Jester Puzzles began as an experimental vision—an adventure into the surreal, blending human creativity with AI-driven imagery to build something truly unique. What started as a fun, artistic exploration quickly transformed into a full-fledged world, where neon lights flicker over masked figures, enigmatic jesters, and dreamlike carnival scenes. Each puzzle is the result of a dynamic collaboration between imagination and technology—crafted through carefully designed prompts, AI-generated source material, and extensive Photoshop refinement to bring every detail to life. The result? A collection of puzzles that don't just challenge the mind but transport you into a strange, wondrous realm where reality bends and magic lingers in the air.";

const puzzleData = {
  0: {
    image: "ANGEL2_FINAL.jpg",
    title: "Ethereal Phoenix Rebirth",
    linkAlias: "ethereal-phoenix-rebirth",
    description:
      "A spectral phoenix, glowing in violet and electric blue, ascends from the void in a breathtaking fusion of light and shadow, embodying the mystery of rebirth.",
    longDescription: `A celestial phoenix ascends, its wings unraveling in luminous streaks of violet and electric blue. Suspended in an ethereal void, this otherworldly figure pulses with raw energy, as if emerging from a cosmic dream. The contrast between shadow and light, deep space and glowing feathers, creates a mesmerizing challenge for puzzle lovers and art enthusiasts alike.`,
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  1: {
    image: "ANGEL3_FINAL.jpg",
    title: "Neon Revenant",
    linkAlias: "neon-revenant",
    description:
      "A spectral figure with massive black wings drifts through a neon-lit city, blurring the line between ghost and guardian.",
    longDescription:
      "A ghostly figure drifts through a rain-soaked neon city, its tattered cloak trailing behind as massive black wings unfurl into the electric night. Shadows flicker in the mist, and digital signs cast an eerie glow over the spectral being's faceless form. Is it an angel of vengeance, a lost soul reborn in neon light, or something far more mysterious? Immerse yourself in the cybernetic afterlife with 'Neon Revenant,' a stunning jigsaw puzzle that merges dark fantasy with the pulse of a futuristic metropolis. The intricate details, glowing reflections, and haunting composition make this puzzle a mesmerizing challenge for those drawn to the surreal and the supernatural.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  2: {
    image: "ANGEL4_FINAL.jpg",
    title: "Frostborn Siren",
    linkAlias: "frostborn-siren",
    description:
      "A mystical ice-born being, adorned in feathers and frost, gazes with an otherworldly stare from a frozen dreamscape.",
    longDescription:
      "In the heart of an eternal winter, she waits—an ethereal being woven from ice, feathers, and forgotten dreams. Her piercing gaze is both mesmerizing and haunting, framed by a crown of frost-kissed plumes that shimmer in the pale glow. Tiny flecks of crystalline dust swirl around her, caught in a frozen breath of time. Is she a guardian of the icy void or a siren luring wanderers into an enchanted frostscape?'Frostborn Siren' invites you into a world where beauty meets the surreal, where a single glance can send shivers down your spine. This intricate jigsaw puzzle blends fantasy, mystery, and the hypnotic allure of the cold, making it a must-have for those who seek the extraordinary.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  3: {
    image: "CAROUSEL_FINAL.jpg",
    title: "The Midnight Carousel",
    linkAlias: "the-midnight-carousel",
    description:
      "A glowing, surreal carousel spins endlessly beneath a starry sky, its vibrant neon horses frozen mid-gallop.",
    longDescription:
      "Under a neon-drenched sky, the Midnight Carousel turns, its glowing lights flickering against the misty air. The painted horses, frozen mid-gallop, seem almost alive—trapped in an endless cycle beneath the cosmic glow of falling stars. The air hums with an eerie, electric energy, as if the ride is waiting for its next traveler... or its next victim. Step into a dreamscape where nostalgia meets the surreal. 'The Midnight Carousel' is a mesmerizing puzzle that captures the mystery and wonder of a carnival that never sleeps. With rich neon hues, deep shadows, and intricate details, this puzzle offers an immersive challenge for those who crave the extraordinary.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  4: {
    image: "CRYSTAL_BALL_FINAL.jpg",
    title: "The Oracle's Gaze",
    linkAlias: "the-oracles-gaze",
    description:
      "A mesmerizing fortune teller peers into an enchanted sphere, her cosmic eyes glimmering with secrets yet to unfold.",
    longDescription:
      "She sees what you cannot. Beneath the swirling carnival lights, the Oracle cradles her enchanted sphere, its golden filigree pulsing with ancient secrets. Her piercing eyes shimmer with cosmic foresight, speckled with glowing dust like distant constellations. Wrapped in opulent silks and adorned with celestial jewels, she waits—for you, for fate, for the next question to be asked. 'The Oracle's Gaze' transports you into a world of mysticism and wonder, where fortune and illusion intertwine. This captivating jigsaw puzzle is filled with intricate patterns, neon glows, and mesmerizing details that will challenge and delight puzzle enthusiasts drawn to the surreal and the mysterious.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  5: {
    image: "ELEPHANT_FINAL.jpg",
    title: "The Psychedelic Maharajah",
    linkAlias: "the-psychedelic-maharajah",
    description:
      "A regal elephant bursts with hypnotic neon patterns, blending surrealism with electrifying festival energy.",
    longDescription:
      "A grand elephant adorned in electric colors and hypnotic patterns gazes through the neon haze of a dream. Swirls of deep blues, radiant yellows, and glowing greens dance across its skin like a festival of light, each intricate design whispering of ancient stories and untold adventures. Crowned in a golden headdress and draped in surreal textures, the Psychedelic Maharaja is both regal and otherworldly—an enigma from a world where reality bends into art. This mesmerizing jigsaw puzzle bursts with vibrant energy and intricate details, offering a dazzling challenge for those who love bold colors, surreal imagery, and a touch of the extraordinary. Perfect for puzzle enthusiasts who crave a piece of neon-infused wonder.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  6: {
    image: "FAERIE_FINAL.jpg",
    title: "The Gilded Fey",
    linkAlias: "the-gilded-fey",
    description:
      "A radiant fairy, cloaked in emerald and gold, soars through an industrial dreamscape, trailing sparks of raw magic.",
    longDescription:
      "She glides through the air like a whisper of magic, her emerald gown swirling as golden energy erupts from her luminous wings. Sparks of enchanted light trail behind her, casting a warm glow against the cold, industrial backdrop. With a flick of her wand, reality bends—has she been summoned, or is she escaping from a world that cannot contain her? 'The Gilded Fey' captures a breathtaking fusion of fantasy and modern mystique, blending delicate elegance with raw, untamed power. This puzzle is a feast for the eyes, filled with intricate details, glowing embers, and an atmosphere that feels both ethereal and electric. A must-have for those who crave enchantment with a touch of the unexpected.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  7: {
    image: "FOX_FINAL.jpg",
    title: "The Phantom Fox",
    linkAlias: "the-phantom-fox",
    description:
      "A masked trickster in ornate attire prowls the midnight carnival, his golden eyes glinting with playful deception.",
    longDescription:
      "In the flickering glow of lanterns, he watches. Cloaked in embroidered finery and a jeweled mask, the Phantom Fox moves through the midnight carnival with effortless grace. His golden eyes gleam with mischief—does he invite you into his world, or is he already three steps ahead in a game only he knows the rules to? 'The Phantom Fox' is a spellbinding puzzle that captures the allure of masked deception, mystery, and elegance. With rich textures, intricate gold detailing, and an atmosphere soaked in deep blues and glowing embers, this puzzle is a mesmerizing challenge for those drawn to the enigmatic and the extraordinary.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  8: {
    image: "GREMLIN_FINAL.jpg",
    title: "The Arcade Gremlin",
    linkAlias: "the-arcade-gremlin",
    description:
      "A mischievous, cyberpunk creature clings to an arcade machine, its cracked neon eyes filled with chaotic energy.",
    longDescription:
      "You hear the clatter of tokens. The flicker of neon signs. The hum of an arcade cabinet coming to life. But something else is here. Perched on the edge of a glowing machine, The Arcade Gremlin grins, its razor-sharp teeth catching the electric light. Its eerie, oversized eyes—cracked like shattered glass—lock onto you as sparks rain down. Did you just win the jackpot, or have you triggered something far more sinister? 'The Arcade Gremlin' is a wild, adrenaline-fueled puzzle bursting with cyberpunk horror, glowing chaos, and intricate details. Perfect for those who love eerie creatures, retro-futuristic neon, and a challenge that feels like stepping into a digital nightmare.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  9: {
    image: "HALL1_FINAL.jpg",
    title: "Neon Oracle's Archive",
    linkAlias: "neon-oracles-archive",
    description:
      "A lone figure stands before an immense wall of glowing screens, each flickering with cryptic images and forgotten secrets.",
    longDescription:
      "🔮 Enter the digital dreamscape. A lone figure stands before a towering neon-lit archive, where glowing screens pulse with cryptic images and lost memories. Is this a gateway to knowledge or a labyrinth of illusions? The deep hues of electric blue and fiery magenta create a mesmerizing cyber-noir atmosphere, drawing you into a world of mystery and endless possibility. Perfect for puzzle lovers who crave cyberpunk aesthetics, futuristic intrigue, and mind-bending detail, this piece offers an immersive challenge. Every piece unlocks a new fragment of this surreal digital oracle.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  10: {
    image: "HALL2_FINAL.jpg",
    title: "The Luminous Hall of Echoes",
    linkAlias: "the-luminous-hall-of-echoes",
    description:
      "A grand, surreal corridor lined with mirrors reflects a world that shimmers between reality and illusion.",
    longDescription:
      "🪞 Step into a hall where reality shatters and reflections whisper secrets. This mesmerizing jigsaw puzzle transports you to a grand corridor bathed in neon light, where elegant mirrors distort time and space. Ethereal figures drift through the glowing passage, their forms lost in an eerie, dreamlike reverie. The walls seem alive, pulsing with vibrant hues of electric blue, fiery orange, and ghostly green, hinting at a world beyond the glass. Perfect for those who love mystical imagery, surreal landscapes, and a touch of the unknown, this puzzle is a portal to a realm where nothing is as it seems. Each piece draws you deeper into the mystery—will you find your way out, or become just another reflection?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  11: {
    image: "HALL3_FINAL.jpg",
    title: "The Gilded Labyrinthe",
    linkAlias: "the-gilded-labyrinthe",
    description:
      "An opulent golden passage twists endlessly, its shimmering reflections and intricate carvings hinting at hidden doors and forgotten paths.",
    longDescription:
      "✨ A corridor of infinite reflections, a path paved with mystery. Step into a dreamlike labyrinth of golden mirrors and deep sapphire walls, where reality bends and the exit is never certain. Shimmering water pools along the floor, reflecting an eerie glow, while ornate gold frames hint at hidden doorways. At the end of the passage, a luminous figure waits—beckoning, watching. Do they hold the key to escape, or are they simply another illusion? This puzzle is perfect for lovers of opulent fantasy, surreal architecture, and hidden secrets. With each intricate piece, you’ll unravel a scene that is both regal and unsettling—a mesmerizing challenge for those who love puzzles as enigmatic as the worlds they reveal.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  12: {
    image: "HALL4_FINAL.jpg",
    title: "The Cosmic Oracle",
    linkAlias: "the-cosmic-oracle",
    description:
      "A cloaked figure with a celestial void for a face stands beneath golden arches, holding the mysteries of the universe in their silent gaze.",
    longDescription:
      "🔮 Gaze into infinity, if you dare. In the heart of a gilded palace, a cloaked figure stands motionless—its face not of flesh, but an endless cosmos of swirling stars. The grand, marble-lined corridor glows with golden light, yet shadows creep at the edges, hinting at secrets beyond time and space. Who is the oracle? A guardian of knowledge or a whisper of the unknown? This puzzle is perfect for those drawn to mystical enigmas, celestial beauty, and surreal dreamscapes. Each piece unlocks a fragment of this haunting vision, where elegance meets the infinite void.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  13: {
    image: "HARLEQUIN1_FINAL.jpg",
    title: "Shattered Dreamscape",
    linkAlias: "shattered-dreamscape",
    description:
      "A fragmented neon prism encases a woman's hypnotic gaze, her glowing eyes staring through the fractures of reality.",
    longDescription:
      "💎 A face caught between reality and illusion. Glowing golden eyes pierce through a fractured prism, reflecting shards of neon light and cosmic dreams. Tangled in a web of delicate lines, the figure’s expression is both hypnotic and enigmatic—are they trapped in the illusion, or are they the architect of this dazzling chaos? Colors explode across the scene in electric blues, fiery reds, and shimmering gold, creating a surreal, cyber-noir fantasy. This puzzle is perfect for lovers of futuristic beauty, fragmented realities, and hypnotic neon aesthetics. Every piece draws you deeper into the mystery, revealing the intricate details of a dreamscape that refuses to stay broken.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  14: {
    image: "HARLEQUIN2_FINAL.jpg",
    title: "Neon Fracture",
    linkAlias: "neon-fracture",
    description:
      "A surreal cyber-noir vision of a woman whose glowing eyes and fractured mask blur the lines between identity and illusion.",
    longDescription:
      "🔮 A face splintered into light, a gaze that sees beyond reality. Beneath a shattered neon prism, glowing red eyes burn with an otherworldly intensity. Reflections of electric pinks, blues, and purples dance across porcelain skin, creating a hypnotic fusion of beauty and distortion. In delicate, adorned hands, a mysterious tool hovers—are they mending the cracks or breaking reality apart? Perfect for those drawn to cyber-noir aesthetics, futuristic surrealism, and hauntingly beautiful mysteries, this puzzle pulls you into a world where light and shadow blur, and nothing is quite as it seems. Each piece unveils more of the hypnotic details hidden within this electrifying vision.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  15: {
    image: "HARLEQUIN3_FINAL.jpg",
    title: "The Crimson Jester",
    linkAlias: "the-crimson-jester",
    description:
      "A mesmerizing trickster clad in a dazzling harlequin outfit stares with piercing blue eyes, her painted grin full of mystery and mischief.",
    longDescription:
      "🎭 A trickster bathed in neon light, her gaze laced with secrets. Beneath the flickering glow of an unseen carnival, the Crimson Jester lies in wait—her icy blue eyes piercing through shattered reflections. A painted smile, smeared like a half-forgotten illusion, hints at both mischief and mystery. Her harlequin attire shimmers in fractured hues of electric blues, fiery reds, and iridescent purples, blurring the line between beauty and chaos. This puzzle is perfect for lovers of dark carnival aesthetics, surreal fantasy, and hypnotic neon-noir imagery. Every piece draws you deeper into her mesmerizing gaze—will you unravel the trick, or become part of her game?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  16: {
    image: "JESTER_FINAL.jpg",
    title: "The Painted Trickster",
    linkAlias: "the-painted-trickster",
    description:
      "A swirling burst of carnival color surrounds a jester whose elaborate mask and piercing stare suggest a game yet to be played.",
    longDescription:
      "🎭 A jester’s smile—inviting, yet unsettling. Twisting through a kaleidoscope of color, the Painted Trickster locks eyes with you, their emerald gaze brimming with secrets. Vivid swirls of gold, violet, and crimson dance across their elaborate mask, a mesmerizing blend of artistry and deception. Every detail—from the intricate face paint to the hypnotic patterns of their costume—invites you deeper into a carnival of mystery. But beware: not all who laugh play fair. Perfect for those drawn to vibrant illusions, masked enigmas, and the surreal magic of jesters, this puzzle challenges both the mind and the senses. With each piece, the trickster's grin grows wider—will you dare to finish the game?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  17: {
    image: "LEVITATION_FINAL.jpg",
    title: "Neon Levitation",
    linkAlias: "neon-levitation",
    description:
      "A golden-clad figure drifts weightlessly through a glowing metropolis, suspended in the electric chaos of a futuristic dreamscape.",
    longDescription:
      "🌆 Drifting through a city of light, lost between gravity and dreams. A lone figure in a golden gown hovers weightlessly above a glowing cyberpunk metropolis, her body suspended in a neon haze of electric blues, fiery reds, and pulsing greens. The world below is alive with energy, yet she floats beyond its reach—untethered, luminous, surreal. Is she ascending toward something greater or dissolving into the neon abyss? This puzzle is perfect for lovers of futuristic dreamscapes, weightless illusions, and hypnotic neon aesthetics. Each piece pulls you deeper into the mystery of her levitation—will you find the truth, or simply drift along with her?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  18: {
    image: "LIGHTNING_FINAL.jpg",
    title: "The Neon Phantom",
    linkAlias: "the-neon-phantom",
    description:
      "Cloaked in darkness, a shadowy figure crackles with violet lightning, moving like a ghost through the neon-lit cityscape.",
    longDescription:
      "⚡ A shadow moves through the electric storm. Cloaked in darkness, the Neon Phantom drifts above a futuristic cityscape, their form crackling with arcs of violet lightning. Neon signs flicker wildly, illuminating the storm that rages behind them—sparks fly, the air hums with energy, and reality itself seems to warp in their presence. Who are they? A lost traveler between worlds or a force beyond comprehension? This puzzle is perfect for those drawn to cyberpunk mysticism, futuristic specters, and high-voltage neon-noir aesthetics. Each piece brings you closer to the mystery—will you uncover the Phantom’s identity, or will they vanish into the neon storm once more?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  19: {
    image: "MADAME_FINAL.jpg",
    title: "Madame Nocturne",
    linkAlias: "madame-nocturne",
    description:
      "Wrapped in deep violet silk and gold adornments, a mysterious woman watches with knowing eyes, a vision of gothic elegance in the night.",
    longDescription:
      "🌙 A vision of midnight elegance, draped in mystery. Reclining in the glow of violet and sapphire light, Madame Nocturne watches in silence—her gaze as deep as the night itself. Adorned in intricate gold jewelry and lace, she is both regal and enigmatic, a figure lost between a dream and reality. The flickering neon hues cast shifting shadows across her delicate features, hinting at secrets untold. Perfect for lovers of gothic beauty, ethereal fantasy, and neon-noir aesthetics, this puzzle weaves together an atmosphere of luxury, mystery, and allure. Every piece reveals another fragment of her world—will you unlock her secrets or remain entranced by her spell?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  20: {
    image: "MARIONETTE_FINAL.jpg",
    title: "Madame Marionette",
    linkAlias: "madame-marionette",
    description:
      "A haunting marionette adorned in lace and roses dangles in a web of wires, her glowing red eyes hinting at a silent, eternal performance.",
    longDescription:
      "🎭 A doll’s face, cracked with time, yet her glowing eyes never blink. Suspended in a tangle of wires, Madame Marionette watches with an eerie stillness. Delicate lace and embroidered fabrics drape her fragile form, while crimson roses bloom against the darkness. Neon threads pulse around her, as if she is part of a grand performance—or a puppet waiting for unseen hands to pull the strings. This puzzle is perfect for those who love haunting beauty, eerie elegance, and the magic of forgotten marionettes. Each piece unravels the mystery of her gaze—does she dance of her own will, or is she forever bound to the strings?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  21: {
    image: "MASKS_FINAL.jpg",
    title: "The Hall of Watchers",
    linkAlias: "the-hall-of-watchers",
    description:
      "A cloaked figure steps into a chamber filled with floating golden masks, their unblinking eyes glowing in eerie green light.",
    longDescription:
      "👁️ One figure stands alone—while countless glowing eyes stare back. A cloaked stranger steps into the Hall of Watchers, where golden masks float in eerie green light, their hollow gazes unblinking. The air hums with an unseen presence, and shadows ripple in the flickering glow. Are these silent faces remnants of forgotten souls, or are they watching, waiting… judging? This puzzle is perfect for those drawn to mystical encounters, surreal dreamscapes, and haunting neon-noir aesthetics. Each piece brings you closer to the heart of this hypnotic vision—but be warned, once you enter the hall, the Watchers never look away.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  22: {
    image: "MONSTER_FINAL.jpg",
    title: "The Carnival Rascal",
    linkAlias: "the-carnival-rascal",
    description:
      "A mischievous, grinning trickster hangs upside down in the electric chaos of a neon carnival, clearly up to no good.",
    longDescription:
      "🎪 Up to no good, and loving every second of it. With a mischievous grin and twinkling blue eyes, The Carnival Rascal hangs upside down amid a maze of glowing lights, clearly in the middle of some delightful trouble. Clad in a well-worn leather jacket and a stitched-together hat, this charming trickster seems to belong to the electric chaos of the carnival. Is he a performer, a wanderer, or just looking for his next big stunt? Perfect for those who love whimsical mischief, neon carnivals, and characters bursting with personality, this puzzle is an adventure waiting to be unraveled. Each piece reveals more of the Rascal’s world—will you join in on his playful escapades?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  23: {
    image: "NEON-QUEEN_FINAL.jpg",
    title: "Madame Luminara",
    linkAlias: "madame-luminara",
    description:
      "A masked woman adorned with glowing red filigree stares with eyes ablaze, a radiant queen of the masquerade.",
    longDescription:
      "🔥 A masked gaze that burns through the darkness. Draped in shimmering jewels and intricate lace, Madame Luminara stares with eyes ablaze, her porcelain features framed by a mask of glowing red filigree. Every bead, every gem, every delicate curve of metal seems to pulse with an unnatural energy, as if the mask itself is alive. Is she the host of a grand masquerade, or something far more mysterious lurking behind the veil? This puzzle is perfect for those drawn to elegant gothic aesthetics, glowing masquerades, and enigmatic figures of the night. Each piece reveals another detail of her haunting beauty—will you dare to meet her gaze?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  24: {
    image: "REVENANT_FNAL.jpg",
    title: "The Shadow Harlequin",
    linkAlias: "the-shadow-harlequin",
    description:
      "Cloaked in flowing black, a masked figure weaves through a neon-lit carnival, an enigma hidden among the revelers.",
    longDescription:
      "🎭 A masked figure drifts through the neon-lit carnival, watching, waiting. Cloaked in shimmering darkness, The Shadow Harlequin moves unseen through the crowd, their expression unreadable behind a cold metallic mask. Strings of golden lights flicker in the distance, laughter echoes in the air, yet this figure stands apart—an enigma in the revelry. Are they a performer? A trickster? Or something far more mysterious lurking beneath the disguise? Perfect for those drawn to masked intrigue, neon carnival mystique, and eerie elegance, this puzzle captures the tension between the seen and the unseen. Every piece brings you closer to revealing their secrets—but do you truly want to know?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  25: {
    image: "RINGMASTER_FINAL.jpg",
    title: "The Carnival Ringmaster",
    linkAlias: "the-carnival-ringmaster",
    description:
      "A sharply dressed showman with a wicked grin raises a toast in the glowing light of a carnival bar, the master of ceremonies in a world of chaos.",
    longDescription:
      "🎪 Step right up—the show is about to begin. With a grin carved from shadows and eyes that gleam like molten gold, The Carnival Ringmaster welcomes you into his world of dazzling lights and whispered tricks. Dressed in embroidered crimson and gold, his presence is both regal and unnerving, a maestro of the unknown. The carnival hums around him, but make no mistake—he is the one pulling the strings. Perfect for those drawn to dark circus intrigue, theatrical decadence, and enigmatic showmen, this puzzle immerses you in the magic and mischief of a neon-lit spectacle. Each piece brings you closer to the mystery—will you be a mere spectator, or part of the act?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  26: {
    image: "TAROT_FINAL.jpg",
    title: "The Arcane Gambler",
    linkAlias: "the-arcane-gambler",
    description:
      "A hooded figure smirks as glowing, enchanted playing cards hover in the air, a high-stakes game of fate unfolding before them.",
    longDescription:
      "♠️ The stakes are high, and the cards are alive with energy. Cloaked in an embroidered hood, The Arcane Gambler locks eyes with you, a knowing smirk hinting at secrets hidden in his glowing deck. Each card crackles with neon-blue magic, suspended in midair as if bending to his will. Is this a simple game of chance, or are the rules written in a language only he understands? Perfect for those drawn to mystical tricksters, high-stakes illusions, and the thrill of the unknown, this puzzle captures the moment between fate and fortune. Each piece unveils another detail of his enchanted gamble—will you take the risk?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  27: {
    image: "THIEF_FINAL.jpg",
    title: "Madame Larceny",
    linkAlias: "madame-larceny",
    description:
      "A masked woman with glowing eyes and delicate lace gloves holds a stolen pocket watch, her presence both alluring and dangerous.",
    longDescription:
      "💎 A thief draped in lace, hidden behind a gilded mask. Madame Larceny moves through the neon-lit carnival like a whisper in the dark, her glowing eyes scanning the room for her next prize. Gold embroidery shimmers across her face, but beneath the elegance lies a mind always one step ahead. A delicate glove, laced with secrets, holds a pocket watch—stolen, no doubt. Time is running out. Will she vanish into the night before you uncover her scheme? Perfect for those drawn to masked intrigue, seductive deception, and the allure of a master thief, this puzzle is a game of illusion and intent. Each piece brings you closer to unveiling her story—just be careful, or she might steal more than your attention.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  28: {
    image: "TIKTOK1_FINAL.jpg",
    title: "The Marionette's Waltz",
    linkAlias: "the-marionettes-waltz",
    description:
      "A delicate puppet dances high above a neon carnival, crackling with blue energy as if awakening to something beyond her strings.",
    longDescription:
      "🎭 Suspended in the night, dancing on invisible strings. Under the glow of crimson carnival lights, The Marionette glides across a wire, sparks of electricity crackling from her delicate hands. Her wooden limbs dangle, swaying in a silent waltz against the deep blue sky, where a sliver of moon watches from afar. Is she controlled by unseen hands, or has she finally cut her strings? Perfect for those drawn to haunting beauty, eerie puppetry, and dreamlike neon aesthetics, this puzzle is an invitation to the strange and the surreal. Each piece reveals another part of her delicate, electric ballet—will you set her free, or leave her to the wires?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  29: {
    image: "TIKTOK2_FNAL.jpg",
    title: "The Marionette's Awakening",
    linkAlias: "the-marionettes-awakening",
    description:
      "Suspended in a web of wires, a gothic marionette channels green lightning, her transformation into something more than a puppet finally beginning.",
    longDescription:
      "⚡ The strings spark with power—who controls who now? Suspended in a web of wires, The Marionette no longer dangles lifelessly. Green lightning crackles from her fingertips, illuminating a carnival of flickering lights and hidden machinery. Her corseted gown flows as if caught in an unseen current, and her expression is unreadable—has she broken free, or is this transformation part of the act? Perfect for those drawn to eerie elegance, electrified illusions, and the haunting beauty of sentient marionettes, this puzzle tells a story of power reclaimed. Each piece brings you closer to unraveling the mystery—will you pull the strings, or will she?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  30: {
    image: "TIME_FINAL.jpg",
    title: "The Clockwork Dreamer",
    linkAlias: "the-clockwork-dreamer",
    description:
      "A celestial face made of gears and golden sparks floats in a dreamlike void, where time itself bends and fractures.",
    longDescription:
      "⏳ Time fractures, gears turn, and the dream continues. Encased in a swirling web of golden sparks and intricate clockwork, The Clockwork Dreamer slumbers between worlds. Their metallic face is adorned with glowing numerals, ticking gears, and celestial symbols—are they trapped in an endless dream, or are they the architect of time itself? Perfect for those drawn to steampunk mysticism, surreal timepieces, and cosmic clockwork, this puzzle is a portal into a world where past, present, and future collide. Each piece reveals another fragment of this intricate dreamscape—will you solve the puzzle before time runs out?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  31: {
    image: "VAGABOND_FINAL.jpg",
    title: "Madame Ember",
    linkAlias: "madame-ember",
    description:
      "A woman cloaked in richly patterned fabric glows with ember-like energy, her piercing gaze holding the warmth and power of a dying star.",
    longDescription:
      "🔥 Her gaze smolders with secrets, her presence flickers like firelight. Wrapped in a tapestry of ancient patterns and glowing embers, Madame Ember watches in silence, her golden eyes burning with cosmic intensity. Tiny sparks dance across her skin, as if she carries the remnants of a forgotten star. Is she a storyteller, a seer, or something far more powerful lurking beneath the veil? Perfect for those drawn to mystical beauty, fiery enchantment, and hypnotic neon dreamscapes, this puzzle captures the allure of a woman who exists between realms. Each piece brings you closer to the truth—but can you withstand her gaze long enough to finish?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  32: {
    image: "VILLAIN_FINAL.jpg",
    title: "The Laughing Gentleman",
    linkAlias: "the-laughing-gentleman",
    description:
      "Dressed in extravagant purples and emeralds, a charismatic trickster raises a glowing cocktail, his grin as sharp as his intentions.",
    longDescription:
      "🍸 A toast to the night, to chaos, to the grandest of games. Dressed in an opulent suit of swirling purples and emerald greens, The Laughing Gentleman raises his glass, a devilish smirk carved across his face. The dim glow of the bar reflects in his eyes—mischievous, dangerous, utterly unpredictable. Is he celebrating victory, or simply reveling in the madness of it all? Perfect for those drawn to eccentric tricksters, neon-noir revelry, and the fine line between charm and danger, this puzzle invites you to step into the world of a man who plays by his own rules. Each piece sharpens his grin—are you ready to join the party?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  33: {
    image: "VIOLIN1_FINAL.jpg",
    title: "The Neon Violinist",
    linkAlias: "the-neon-violinist",
    description:
      "A lone violinist plays beneath the flickering lights of the city, her melody a haunting echo in the neon-drenched streets.",
    longDescription:
      "🎻 A melody drifts through the neon-lit streets, haunting and electric. Bathed in crimson and magenta glow, The Neon Violinist loses herself in the music, her bow gliding across the strings with hypnotic intensity. Wind tousles her dark hair, her eyes heavy with emotion, a scar tracing her cheek like the remnants of an untold story. The city hums around her, but in this moment, she is the only sound that matters. Perfect for those drawn to melancholic beauty, cyberpunk symphonies, and the power of a single song in the night, this puzzle immerses you in the world of a musician whose music is more than just notes—it’s a pulse, a cry, a story waiting to be heard. Each piece sharpens the song—will you hear it all before the final note fades?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  34: {
    image: "VIOLIN2_FINAL.jpg",
    title: "The Crimson Violinist",
    linkAlias: "the-crimson-violinist",
    description:
      "A mysterious musician cloaked in shadow plays a sorrowful tune as rain glistens in the red glow of lantern-lit alleyways.",
    longDescription:
      "🎻 A melody weaves through the rain, haunting and untamed. Cloaked in midnight lace, The Crimson Violinist plays as the storm rages around her. Raindrops catch the glow of hanging lanterns, casting a soft shimmer against her flowing silhouette. Her bow glides across the strings with hypnotic grace, her music shaping the very air—an aching ballad, a whispered confession, a song only the city can hear. Perfect for those drawn to melancholic beauty, orchestral mystique, and the artistry of sound in the shadows, this puzzle immerses you in a world where music is both refuge and rebellion. Each piece brings you closer to the final note—but will you finish before the rain washes it away?",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
  35: {
    image: "ANGEL1_FINAL.jpg",
    title: "Neon Phoenix Ascendant",
    linkAlias: "neon-phoenix-ascendant",
    description:
      "A radiant phoenix erupts in crimson and magenta light, soaring through a futuristic neon cityscape in a dazzling display of energy and motion.",
    longDescription:
      "Step into a cybernetic dreamscape where the Neon Phoenix rises from the digital abyss. This electrifying jigsaw puzzle captures a surreal, winged figure exploding into crimson and magenta light, set against a futuristic city pulsing with neon signs and electric energy. The intricate details and hypnotic contrast between shadow and glow make this puzzle an immersive challenge and a striking piece of art.",
    amazonLink: "#eee",
    merch2Link: "#aaa",
    merch3Link: "#bbb",
    merch4Link: "#ccc",
    merch5Link: "#ddd",
  },
};

export { puzzleData, aboutPuzzleData };
